import { CancelCallButton, PaginatedGridLayout, ReactionsButton, RecordCallConfirmationButton, ScreenShareButton, SpeakerLayout, SpeakingWhileMutedNotification, ToggleAudioPublishingButton, ToggleVideoPublishingButton, useCallStateHooks } from "@stream-io/video-react-sdk";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";


export default function ActiveCallScreen() {
    const onLeave = () => {
        console.log('Leaving call');
    }
    return (
        <>
            <Stage />
            <CustomCallControls onLeave={onLeave} />
        </>
    )
}


const LayoutMap = {
    Speaker: {
        component: SpeakerLayout,
        title: 'Speaker layout with spotlight',
        props: {},
    },
    PaginatedGrid: {
        component: PaginatedGridLayout,
        title: 'Paginated grid layout',
        props: {
            groupSize: 12,
        },
    },
};

function Stage() {
    const [selectedLayout, setSelectedLayout] = useState('PaginatedGrid');
    const { useHasOngoingScreenShare } = useCallStateHooks();
    const hasOngoingScreenShare = useHasOngoingScreenShare();

    const LayoutComponent = LayoutMap[selectedLayout].component;
    const componentProps = LayoutMap[selectedLayout].props;

    useEffect(() => {
        if (hasOngoingScreenShare) return setSelectedLayout('Speaker');

        return setSelectedLayout('PaginatedGrid');
    }, [hasOngoingScreenShare]);

    return <LayoutComponent {...componentProps} />;
};


CustomCallControls.propTypes = {
    onLeave: PropTypes.func.isRequired,
};
function CustomCallControls({ onLeave }) {
    return (
        <div className="str-video__call-controls" style={{ position: 'fixed', width: '100%', bottom: '2rem' }}>
            <SpeakingWhileMutedNotification>
            <ToggleAudioPublishingButton />
            </SpeakingWhileMutedNotification>
            <ToggleVideoPublishingButton />
            <ReactionsButton />
            <ScreenShareButton />
            <RecordCallConfirmationButton />
            <CancelCallButton onLeave={onLeave} />
        </div>
    );
}