import { useContext, useMemo } from "react";
import { CallingState, StreamTheme, useCall, useCallStateHooks, useStreamVideoClient } from "@stream-io/video-react-sdk";
import { Backdrop } from "@mui/material";
import { CenterBox } from "../center-box";
import LobbyScreen from "./lobby-screen";
import ActiveCallScreen from "./active-call-screen";
import AVCallContext from "../../contexts/AVCallContext";


export default function CallLayer() {
    const client = useStreamVideoClient();
    const call = useCall();

    if (!call || !client) {
        return null;
    }

    const open = ![CallingState.LEFT, CallingState.RINGING].includes(call.state.callingState);

    return (
        <Backdrop open={open} sx={{ zIndex: 8000 }} style={{ display: 'block', color: 'white', padding: '2rem' }}>
            <StreamTheme>
                {open && <MyUILayout />}
            </StreamTheme>
        </Backdrop>
    )
}

export const MyUILayout = () => {
    const { setCurrentCall } = useContext(AVCallContext);
    const {
        useCallCallingState
    } = useCallStateHooks();
    const callingState = useCallCallingState();

    console.log("Calling state", callingState);

    const Component = useMemo(() => {
        switch (callingState) {
            case CallingState.UNKNOWN:
            case CallingState.IDLE:
                return <LobbyScreen />;

            case CallingState.JOINING:
                return <LoadingCallScreen />;

            case CallingState.JOINED:
                return <ActiveCallScreen />;

            case CallingState.LEFT:
                setCurrentCall(null);
                return null;

            case CallingState.RECONNECTING:
            case CallingState.MIGRATING:
                return <RestoringConnectionScreen />;

            case CallingState.RECONNECTING_FAILED:
                return <GeneralConnectionErrorScreen />;

            case CallingState.OFFLINE:
                return <NoConnectionScreen />;

            default:
                throw new Error(`Unknown calling state: ${callingState}`);
        }
    }, [callingState, setCurrentCall]);

    return Component;
};

function LoadingCallScreen() {
    return <CenterBox>Joining call...</CenterBox>;
}

function RestoringConnectionScreen() {
    return <CenterBox>Restoring connection...</CenterBox>;
}

function GeneralConnectionErrorScreen() {
    return <CenterBox>Connection error</CenterBox>;
}

function NoConnectionScreen() {
    return <CenterBox>No connection</CenterBox>;
}
