import { Box } from "@mui/material";
import PropTypes from "prop-types";

CenterBox.propTypes = { children: PropTypes.node, sx: PropTypes.object };
export function CenterBox({ children, sx, ...rest }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', ...sx }} {...rest}>
            {children}
        </Box>
    );
}