import { useStreamVideoClient } from "@stream-io/video-react-sdk";
import { useEffect, useState } from "react";

export default function useChatCall(chatId) {
    const [chatCall, setChatCall] = useState(null);
    const client = useStreamVideoClient();

    useEffect(() => {
        (async () => {
            const { calls: fetchedCalls } = await client.queryCalls({
                filter_conditions: {
                    'custom.chatId': chatId,
                    $or: [
                        { ongoing: true },
                        { starts_at: { $gt: (new Date()).toISOString() } },
                    ],
                },
                sort: [{ field: 'created_at', direction: -1 }],
                limit: 2,
                watch: true,
            });

            const newCalls = fetchedCalls.filter((call) => {
                if (call.state.startsAt && call.state.endedAt) {
                    return false;
                }
                return true;
            });

            setChatCall(newCalls.length ? newCalls[0] : null);
            console.log("useChatCall | Calls table : ", newCalls);
        })();
    }, [client, chatId]);

    useEffect(() => {
        const queryCall = async (callResponse) => {
            const { calls } = await client.queryCalls({
                filter_conditions: { cid: callResponse.cid },
                limit: 1
            });
            if (calls.length)
                return calls[0];

            throw Error("Call not found");
        }

        const unsubscribes = [
            client.on('call.session_started', (event) => {
                const { call: callResponse } = event;
                if (callResponse.custom?.chatId === chatId) {
                    queryCall(callResponse).then(setChatCall);
                    console.log("useChatCall | Session started : ", callResponse);
                }
            }),
            client.on('call.created', (event) => {
                const { call: callResponse } = event;
                if (callResponse.custom?.chatId === chatId && callResponse.starts_at) {
                    queryCall(callResponse).then((call) => {
                        console.log("use chat call", call)
                        setChatCall(call);
                    });
                    console.log("useChatCall | Call scheduled : ", callResponse);
                }
            })
        ];

        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        }
    }, [client, chatId]);

    useEffect(() => {
        if (!chatCall) {
            return () => { };
        }

        const reset = () => setChatCall(null);

        const unsubscribes = [
            chatCall.on('call.session_ended', reset),
            chatCall.on('call.ended', reset)
        ];

        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        }
    }, [chatCall]);

    return chatCall;
}