import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { StreamCall, useCallStateHooks } from '@stream-io/video-react-sdk';
import { SnackbarContent, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect } from 'react';
import AVCallContext from '../../contexts/AVCallContext';


const CallNotificationContent = forwardRef(({ call, id: snackId, ...props }, ref) => {
    const { currentCall, setCurrentCall } = useContext(AVCallContext);
    const { isCreatedByMe } = call;
    const { closeSnackbar } = useSnackbar();
    const { useCallCustomData } = useCallStateHooks();
    const customData = useCallCustomData();

    const accept = async () => {
        console.log('Accepting call');
        try {
            if (currentCall) {
                await currentCall.leave({ reject: true });
            }
        } catch (e) {
            console.error('Error while living call', e);
        }

        
        try {
            await call.join();
            setCurrentCall(call);
        } catch (e) {
            console.error('Error accepting call', e);
        }
        closeSnackbar(snackId);
    };

    const reject = async () => {
        console.log('Rejecting call');
        try {
            await call.leave({ reject: true });
        } catch (e) {
            console.error('Error rejecting call', e);
        }
        console.log('End Rejecting call');
        closeSnackbar(snackId);
    };

    const cancel = () => {
        console.log('Cancelling call');
        call.leave()
            .catch((e) => {
                console.error('Error cancelling call', e);
            })
            .finally(() => {
                closeSnackbar(snackId);
            });
    };

    useEffect(() => {
        const unsubscribes = [
            call.on('call.rejected', (event => {
                const rejectedBy = event.user;
                if (rejectedBy.id === call.state.createdBy.id) {
                    closeSnackbar(snackId);
                }
            })),
            call.on('call.accepted', () => {
                if (call.isCreatedByMe) {
                    closeSnackbar(snackId);
                    setCurrentCall(call);
                }
            }),
        ];

        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        }
    }, [call, closeSnackbar, setCurrentCall, snackId]);

    return (
        <SnackbarContent
            ref={ref}
            role='alert'
            {...props}
        >
            <Card>
                <CardContent>
                    <Typography variant='h6' component='h2'>
                        {call ? `${isCreatedByMe ? 'Outgoing' : 'Incoming'} call from ${customData?.chatName}` : "No call"}
                    </Typography>
                    <Typography variant='body1' component='p'>
                        By: {call.state.createdBy.name}
                    </Typography>
                </CardContent>
                <CardActions>
                    {
                        isCreatedByMe ? (
                            <Button onClick={cancel}>Cancel</Button>
                        ) : (
                            <>
                                <Button onClick={accept}>Accept</Button>
                                <Button onClick={reject}>Reject</Button>
                            </>
                        )
                    }
                </CardActions>
            </Card>
        </SnackbarContent>
    );
});
CallNotificationContent.propTypes = {
    call: PropTypes.object,
    id: PropTypes.number,
};

const CallNotification = forwardRef(({ call, ...props }, ref) => (
    <StreamCall call={call}>
        <CallNotificationContent ref={ref} call={call} {...props} />
    </StreamCall>
));
CallNotification.propTypes = {
    call: PropTypes.object,
    style: PropTypes.object,
};


export default CallNotification;