

const SERVER_URL = 'https://operator-service.tradetrackr.ru';
// const SERVER_URL = 'http://localhost:3001';

export async function fetchGetStreamToken(userId) {
    const response = await fetch(`${SERVER_URL}/generate-user-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
    });
    const data = await response.json(); 
    return data.token;
}

export async function upsertUsers(users) {
    const response = await fetch(`${SERVER_URL}/upsert-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(users),
    });
    const data = await response.json();
    return data;
}