import { CameraAlt, Close } from '@material-ui/icons';
import {
    useConnectedUser,
    DefaultVideoPlaceholder,
    VideoPreview,
    LoadingIndicator,
    useCall,
    ToggleAudioPublishingButton,
    ToggleVideoPublishingButton,
    useCallStateHooks,
    Avatar,
} from '@stream-io/video-react-sdk';
import { Login } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CenterBox } from '../center-box';

const LobbyScreen = () => {
    const call = useCall();

    if (!call) {
        return null;
    }

    return (
        <CenterBox sx={{ flexDirection: 'column', gap: '1rem' }}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">
                <Typography variant="h4">Set up your call before joining</Typography>
                <Typography variant="body1">while we are connecting you to the call...</Typography>

                <Box position="relative" marginBlock="1rem">
                    <VideoPreview
                        DisabledVideoPreview={DisabledVideoPreview}
                        NoCameraPreview={NoCameraPreview}
                        StartingCameraPreview={StartingCameraPreview}
                    />

                    <Box display="flex" flexDirection="row" justifyContent="center" gap="1rem" position="absolute" bottom="2rem" width="100%">
                        <ToggleVideoPublishingButton />
                        <ToggleAudioPublishingButton />
                    </Box>

                    <Box position="absolute" top="1rem" right="1rem">
                        <IconButton onClick={() => call.leave()} color="white">
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
                <ParticipantsPreview />
                <Button variant="contained" size="large" color="primary" fullWidth onClick={() => call.join()} startIcon={<Login />}>Start Call</Button>
            </Box>
        </CenterBox>
    );
};

function ParticipantsPreview() {
    const { useCallSession } = useCallStateHooks();
    const session = useCallSession();

    if (!session?.participants || session?.participants.length === 0) return null;

    return (
        <div>
            <div>Already in call ({session?.participants.length}):</div>
            <Box display="flex" gap="1rem" marginTop="1rem">
                {session?.participants.map((participant, index) => (
                    <Box key={participant.user.name || index} display="flex" gap=".25rem" flexDirection="column" alignItems="center">
                        <Avatar
                            name={participant.user.name}
                            imageSrc={participant.user.image}
                        />
                        {participant.user.name && <div>{participant.user.name}</div>}
                    </Box>
                ))}
            </Box>
        </div>
    );
}

function DisabledVideoPreview() {
    const connectedUser = useConnectedUser();
    if (!connectedUser) return null;

    return (
        <DefaultVideoPlaceholder
            participant={
                {
                    image: connectedUser.image,
                    name: connectedUser.name,
                }
            }
        />
    );
}

function NoCameraPreview() {
    return (
        <div>
            <CameraAlt />
        </div>
    );
}

function StartingCameraPreview() {
    return (
        <div>
            <LoadingIndicator />
        </div>
    );
}

export default LobbyScreen;