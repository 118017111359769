import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'; 
import { AVCallProvider } from './contexts/AVCallContext';
// routes
import "./i18n";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import './App.css';
// ----------------------------------------------------------------------

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(utc);

export default function App() {
    return (
        <AVCallProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                adapterLocale={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                <HelmetProvider>
                    <ThemeProvider>
                        <ScrollToTop />
                        <StyledChart />
                        <Router />
                    </ThemeProvider>
                </HelmetProvider>
            </LocalizationProvider>
        </AVCallProvider>
    );
}
